<template>
  <div class="adsItem">
    <div ref="adContainer" class="ad-double-container" />
    <div v-if="showDebug" class="ad-msg">
      {{ adType }}_{{ adPosition }} = {{ getAdConfig() }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    adPosition: {
      type: Number,
      required: true
    },
    adType: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      adsense: "adsenseConfig",
      showDebug: "showDebug"
    })
  },
  mounted () {
    this.insertAdScripts();
    // 使用获取到的值
    const { maxWidth, maxHeight } = this.getContainerMaxDimensions();
    console.log('Max Width:', maxWidth, 'Max Height:', maxHeight);
  },
  methods: {
    getContainerMaxDimensions () {
      // 获取 adContainer 的引用
      const adContainer = this.$refs.adContainer;

      // 获取计算后的样式
      const computedStyle = window.getComputedStyle(adContainer);

      // 获取 max-width 和 max-height 的值
      const maxWidth = computedStyle.getPropertyValue('max-width');
      const maxHeight = computedStyle.getPropertyValue('max-height');

      // 移除单位并将值转换为数值类型，然后进行四舍五入
      const maxWidthValue = Math.round(parseFloat(maxWidth));
      const maxHeightValue = Math.round(parseFloat(maxHeight));

      console.log('Max Width:', maxWidthValue, 'Max Height:', maxHeightValue);

      return {
        maxWidth: maxWidthValue,
        maxHeight: maxHeightValue
      };
    },
    getAdConfig () {
      console.log(`${this.adType}_${this.adPosition}`);
      return this.adsense[`${this.adType}_${this.adPosition}`] || {};
    },
    insertAdScripts () {
      const adConfig = this.getAdConfig();
      console.log(adConfig);

      const { maxWidth, maxHeight } = this.getContainerMaxDimensions();
      console.log('Max Width:', maxWidth, 'Max Height:', maxHeight);

      if (adConfig.headScript) {
        adConfig.headScript = adConfig.headScript.replace(/\[\d+,\s*\d+\]/, `[${maxWidth}, ${maxHeight}]`);
      }
      if (adConfig.bodyScript) {
        adConfig.bodyScript = adConfig.bodyScript.replace(/min-width:\s*\d+px;/, `min-width: ${maxWidth}px;`);
        adConfig.bodyScript = adConfig.bodyScript.replace(/min-height:\s*\d+px;/, `min-height: ${maxHeight}px;`);
        adConfig.bodyScript = adConfig.bodyScript.replace(/max-width:\s*\d+px;/, `max-width: ${maxWidth}px;`);
        adConfig.bodyScript = adConfig.bodyScript.replace(/max-height:\s*\d+px;/, `max-height: ${maxHeight}px;`);
      }

      if (adConfig.headScript) {
        const head = document.head || document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.text = adConfig.headScript;
        head.appendChild(script);
      }

      if (adConfig.bodyScript) {
        this.$refs.adContainer.innerHTML = adConfig.bodyScript;
        const scripts = this.$refs.adContainer.getElementsByTagName('script');
        for (let script of scripts) {
          const newScript = document.createElement('script');
          newScript.type = 'text/javascript';
          newScript.text = script.text;
          document.body.appendChild(newScript);
        }
      }
    }
  }
}
</script>

